import { uniqWith } from "lodash";
import {
  Edge,
  Graph,
  Maybe,
  Node,
  PipelineType,
} from "../../graphql/generated";
import { hasPipelineTypeFlag } from "../../types/configuration";
import { AttributeName } from "../PipelineGraphV2/types";

export type NodeId = string;
export type EdgeId = string;

export class BPGraph {
  private graph: Graph;
  private pipelineType: PipelineType;
  constructor(pipelineType: PipelineType, graph?: Maybe<Graph>) {
    if (graph == null) {
      // default to an empty graph
      graph = {
        attributes: {},
        edges: [],
        intermediates: [],
        sources: [],
        targets: [],
      };
    }
    this.graph = graph;
    this.pipelineType = pipelineType;
  }

  /**
   * getSources returns all of the source nodes for the given pipeline
   */
  getSources(): Node[] {
    return this.graph.sources.filter((source) =>
      hasPipelineTypeFlag(this.pipelineType, this.supportedTypeFlags(source)),
    );
  }

  /**
   * getDestinations returns all of the destination nodes for the given pipeline
   */
  getDestinations(): Node[] {
    return this.graph.targets.filter((target) =>
      hasPipelineTypeFlag(this.pipelineType, this.supportedTypeFlags(target)),
    );
  }

  /**
   * getIntermediates returns all of the intermediate nodes for the given pipeline
   */
  getIntermediates(): Node[] {
    return this.graph.intermediates.filter((intermediate) => {
      return hasPipelineTypeFlag(
        this.pipelineType,
        this.supportedTypeFlags(intermediate),
      );
    });
  }

  /**
   * getAllNodes returns all nodes in the graph
   */
  getAllNodes(): Node[] {
    return [
      ...this.graph.sources,
      ...this.graph.intermediates,
      ...this.graph.targets,
    ];
  }

  /**
   * findNode returns the node with the given id
   */
  findNode(id: NodeId): Node | undefined {
    return this.getAllNodes().find((node) => node.id === id);
  }

  /**
   * getNodesFromPath returns the nodes that are in the given path
   */
  getNodesFromPath(path: GraphPath): Node[] {
    return path.path
      .map((id) => this.findNode(id))
      .filter((n) => n !== undefined) as Node[];
  }

  /**
   * getNextNodes follows all of the edges from the given node and returns the nodes that
   * are the target of those edges
   */
  getNextNodes(id: NodeId): Node[] {
    const edges = this.getEdgesOutbound(id);
    return edges
      .map((edge) => this.findNode(edge.target))
      .filter((n) => n !== undefined) as Node[];
  }

  /**
   * getEdgesOutbound returns all edges that have the given node as the source
   */
  getEdgesOutbound(id: NodeId): Edge[] {
    return this.graph.edges.filter(
      (edge) => edge.source === id && this.isForPipelineType(edge),
    );
  }

  /**
   * getEdgesInbound returns all edges that have the given node as the target
   */
  getEdgesInbound(id: NodeId): Edge[] {
    return this.graph.edges.filter(
      (edge) => edge.target === id && this.isForPipelineType(edge),
    );
  }

  /**
   * getNodesInbound returns all nodes that have an edge with the given node as the target
   */
  getNodesInbound(id: NodeId): Node[] {
    const edges = this.getEdgesInbound(id);
    return edges
      .map((edge) => this.findNode(edge.source))
      .filter((n) => n !== undefined) as Node[];
  }

  /**
   * first neighbors returns the nodes that are directly connected to the given node
   */
  firstNeighbors(id: NodeId): Node[] {
    return [...this.getNodesInbound(id), ...this.getNextNodes(id)];
  }

  connectedNodesAndEdges(nodeId: string) {
    const visitedNodes = new Set();
    const visitedEdges = new Set();
    const result: string[] = [];

    const depthFirst = (currentNode: string, direction?: "right" | "left") => {
      if (visitedNodes.has(currentNode)) return;
      visitedNodes.add(currentNode);
      result.push(currentNode);

      this.graph.edges.forEach((edge) => {
        if (
          edge.source === currentNode &&
          !visitedEdges.has(edge.id) &&
          direction !== "left"
        ) {
          visitedEdges.add(edge.id);
          result.push(edge.id); // Add edge ID to the result
          depthFirst(edge.target, "right"); // Traverse to the target node
        }
        if (
          edge.target === currentNode &&
          !visitedEdges.has(edge.id) &&
          direction !== "right"
        ) {
          visitedEdges.add(edge.id);
          result.push(edge.id); // Add edge ID to the result
          depthFirst(edge.source, "left"); // Traverse to the source node
        }
      });
    };

    depthFirst(nodeId);
    return result;
  }

  /**
   * getPaths returns all paths in the graph
   */
  getPaths(): GraphPath[] {
    const result: GraphPath[] = [];

    // start paths at the sources
    for (const source of this.getSources()) {
      const paths = this.getPathsFrom(source.id);
      result.push(...paths);
    }
    // include any unreferenced intermediates
    for (const intermediate of this.getIntermediates()) {
      if (!isVisited(intermediate, result)) {
        const paths = this.getPathsFrom(intermediate.id);
        for (const path of paths) {
          // prepend space for source and source processors
          result.push(path.prepend("", ""));
        }
      }
    }
    // targets should always get picked up by destination processor nodes

    // only return unique results
    return uniqWith(result, (a, b) => a.path.join("|") === b.path.join("|"));
  }

  /**
   * getPathsFrom returns all paths that start at the given node
   */
  getPathsFrom(id: NodeId): GraphPath[] {
    const next = this.getNextNodes(id);
    if (next.length === 0) {
      return [new GraphPath([id])];
    }
    const result: GraphPath[] = [];
    for (const nextId of next) {
      const nextPaths = this.getPathsFrom(nextId.id);
      for (const path of nextPaths) {
        result.push(path.prepend(id));
      }
    }
    return result;
  }

  /**
   * getMaxPathLength returns the length of the longest path in the graph. This will
   * determine the width of the graph layout.
   */
  getMaxPathLength(): number {
    return this.getLongestPath().path.length;
  }

  /**
   * getLongestPath returns the longest path in the graph
   */
  getLongestPath(): GraphPath {
    return this.getPaths().reduce((longest, path) => {
      return path.path.length > longest.path.length ? path : longest;
    }, new GraphPath([]));
  }

  findNodes(fn: (n: Node) => boolean): Node[] {
    return this.getAllNodes().filter(fn);
  }

  /**
   * returns the supported type flags for the given node
  // if the node is a processor list, it will return the intersection
  // of supported type flags for all connected nodes
   * @param node 
   * @param visited optional list of node ids that have already been visited
   * @returns 
   */
  supportedTypeFlags(node: Node, visited?: string[]): number {
    if (node.type !== "processorListNode") {
      return node.attributes[AttributeName.SupportedTypeFlags];
    }

    return this.firstNeighbors(node.id).reduce<number>((flags, n) => {
      if (visited?.includes(n.id)) {
        return flags;
      }
      const nextVisited = visited || [];
      return flags | this.supportedTypeFlags(n, [...nextVisited, node.id]);
    }, 0);
  }

  isForPipelineType(edge: Edge) {
    return edge.attributes[AttributeName.PipelineType] === this.pipelineType;
  }
}

export class GraphPath {
  constructor(public path: string[]) {}

  /**
   * prepend returns a new GraphPath with the given list of ids prepended to the path
   */
  prepend(...id: string[]): GraphPath {
    return new GraphPath([...id, ...this.path]);
  }

  /**
   * append returns a new GraphPath with the given list of ids appended to the path
   */
  append(...id: string[]): GraphPath {
    return new GraphPath([...this.path, ...id]);
  }
}

function isVisited(node: Node, paths: GraphPath[]): boolean {
  return paths.some((path) => path.path.includes(node.id));
}

// isSelectable returns true if the edge is not connecting a source or
// destination to its processor
export function isSelectable(edgeId: string) {
  const [source, target] = edgeId.split("|");
  if (
    source.startsWith("source/") &&
    !source.endsWith("/processors") &&
    target.endsWith("/processors")
  ) {
    return false;
  }
  if (
    target.startsWith("destination/") &&
    !target.endsWith("/processors") &&
    source.endsWith("/processors")
  ) {
    return false;
  }
  return true;
}

export const isDeletable = isSelectable;
