import { createContext, useContext, useRef, useState } from "react";
import { PipelineType } from "../../graphql/generated";
import { BPGraph } from "../ConfigurationFlowV2/graph";
import { useV2PipelineGraph } from "./PipelineGraphV2Context";

interface BPGraphProviderProps {
  pipelineType: PipelineType;
}

interface BPGraphContextValue {
  // hoveredSet is a list of node and edge IDs that are connected
  // to the currently hovered node.
  hoveredSet: string[];
  // onMouseEnterEdge is a callback that should be called when an edge is hovered.
  onMouseEnterEdge: (edgeID: string) => void;
  // onMouseExitEdge is a callback that should be called when an edge is no longer hovered.
  onMouseExitEdge: () => void;
  // hoveredEdge is the ID of the currently hovered edge.
  hoveredEdge: string | null;

  // hoveredNode is the ID of the currently hovered node.
  hoveredNode: string | null;
  // onMouseEnterNode is a callback that should be called when a node is hovered.
  onMouseEnterNode: (nodeID: string) => void;
  // onMouseExitNode is a callback that should be called when a node is no longer hovered.
  onMouseExitNode: () => void;

  graph: BPGraph;
}

const graphContext = createContext<BPGraphContextValue>({
  hoveredSet: [],
  onMouseEnterEdge: () => {},
  onMouseExitEdge: () => {},
  hoveredEdge: null,
  hoveredNode: null,
  onMouseEnterNode: () => {},
  onMouseExitNode: () => {},
  graph: new BPGraph(PipelineType.Logs, null),
});

type Timeout = ReturnType<typeof setTimeout>;

export const BPGraphProvider: React.FC<BPGraphProviderProps> = ({
  pipelineType,
  children,
}) => {
  const { configuration } = useV2PipelineGraph();
  const graph = new BPGraph(pipelineType, configuration?.graph);

  const hoveredEdgeTimeoutRef = useRef<Timeout | null>(null);
  const hoveredNodeTimeoutRef = useRef<Timeout | null>(null);

  const [hoveredEdge, setHoveredEdge] = useState<string | null>(null);
  const [hoveredNode, setHoveredNode] = useState<string | null>(null);
  const [hoveredSet, setHoveredSet] = useState<string[]>([]);

  const onMouseEnterEdge = (edgeID: string) => {
    if (hoveredEdgeTimeoutRef.current) {
      clearTimeout(hoveredEdgeTimeoutRef.current);
    }
    setHoveredEdge(edgeID);
  };
  const onMouseExitEdge = () => {
    hoveredEdgeTimeoutRef.current = setTimeout(() => {
      setHoveredEdge(null);
    }, 1500);
  };

  const onMouseEnterNode = (nodeID: string) => {
    if (hoveredNodeTimeoutRef.current) {
      clearTimeout(hoveredNodeTimeoutRef.current);
    }
    setHoveredNode(nodeID);
    setHoveredSet(graph.connectedNodesAndEdges(nodeID));
  };
  const onMouseExitNode = () => {
    hoveredNodeTimeoutRef.current = setTimeout(() => {
      setHoveredNode(null);
      setHoveredSet([]);
    }, 500);
  };

  return (
    <graphContext.Provider
      value={{
        hoveredSet,
        onMouseEnterEdge,
        onMouseExitEdge,
        hoveredEdge,
        hoveredNode,
        onMouseEnterNode,
        onMouseExitNode,
        graph,
      }}
    >
      {children}
    </graphContext.Provider>
  );
};

export const useBPGraph = () => useContext(graphContext);
