import { cloneDeep } from "lodash";
import { Edge, MarkerType, Node, Position } from "reactflow";
import { PipelineType } from "../../graphql/generated";
import { PipelineTypeFlags } from "../../types/configuration";
import { EdgeData } from "../../utils/graph/types";
import { pipelineOffsets } from "../../utils/graph/utils";
import { isSourceID } from "../PipelineGraph/Nodes/ProcessorNode";
import { isNodeDisabled } from "../PipelineGraph/Nodes/nodeUtils";
import { AttributeName, V2Config, V2NodeData } from "../PipelineGraphV2/types";
import { BPGraph } from "./graph";
import { LayoutGrid } from "./layout-grid";

export function layoutV2Graph(
  configuration: V2Config,
  readOnly: boolean,
  pipelineType: PipelineType,
  onAddSource: () => void,
  onAddDestination: () => void,
): {
  nodes: Node<V2NodeData>[];
  edges: Edge<EdgeData>[];
} {
  const graph = cloneDeep(configuration?.graph);
  if (!graph) {
    return { nodes: [], edges: [] };
  }

  // add node positions
  const bpGraph = new BPGraph(pipelineType, graph);
  const grid = new LayoutGrid(bpGraph);

  const nodes: Node<V2NodeData>[] = [];
  const edges: Edge<EdgeData>[] = [];
  const offsets = pipelineOffsets(graph.edges);

  // if there's only one source or one destination we need to layout add source and add destination cards
  // we also need to add edges between the source/destination and the add source/add destination cards

  const addSourceCard = bpGraph.getSources().length === 0;
  const addDestinationCard = bpGraph.getDestinations().length === 0;

  // layout sources
  if (addSourceCard) {
    nodes.push({
      id: "add-source",
      data: {
        buttonText: "Source",
        handlePosition: Position.Right,
        handleType: "source",
        onClick: onAddSource,
        attributes: {
          [AttributeName.ActiveTypeFlags]: PipelineTypeFlags.None,
          [AttributeName.SupportedTypeFlags]: PipelineTypeFlags.ALL,
        },
        telemetryType: pipelineType,
      },
      position: grid.getPosition("add-source", "sourceNode"),
      type: "uiControlNode",
    });

    nodes.push({
      id: "add-source-proc",
      data: {
        attributes: {},
        telemetryType: pipelineType,
      },
      position: grid.getPosition("add-source-proc", "dummyProcessorNode"),
      type: "dummyProcessorNode",
    });

    var edge: Edge<EdgeData> = {
      id: "add-source_add-source-proc",
      source: "add-source",
      target: "add-source-proc",
      markerEnd: {
        type: MarkerType.ArrowClosed,
      },
      data: {
        connectedNodesAndEdges: [],
        attributes: {
          [AttributeName.ActiveTypeFlags]: PipelineTypeFlags.ALL,
          [AttributeName.SupportedTypeFlags]: PipelineTypeFlags.ALL,
        },
        telemetryType: pipelineType,
      },
      type: "configurationEdge",
    };
    edges.push(edge);
    // connect add-source-proc to all the destination processors
    for (let i = 0; i < (bpGraph.getIntermediates() ?? []).length; i++) {
      const n = bpGraph.getIntermediates()[i];
      if (!isSourceID(n.id)) {
        edge = {
          id: `${n.id}_add-source-proc`,
          target: `${n.id}`,
          source: "add-source-proc",
          markerEnd: {
            type: MarkerType.ArrowClosed,
          },
          data: {
            connectedNodesAndEdges: [],
            attributes: {
              [AttributeName.ActiveTypeFlags]: PipelineTypeFlags.ALL,
              [AttributeName.SupportedTypeFlags]: PipelineTypeFlags.ALL,
            },
            telemetryType: pipelineType,
          },
          type: "configurationEdge",
        };
        edges.push(edge);
      }
    }
  } else {
    for (let i = 0; i < (bpGraph.getSources() ?? []).length; i++) {
      const n = bpGraph.getSources()[i];

      nodes.push({
        id: n.id,
        data: {
          attributes: n.attributes,
          telemetryType: pipelineType,
        },
        position: grid.getPosition(n.id),
        sourcePosition: Position.Right,
        type: n.type,
      });
    }
  }

  // layout processors
  for (let i = 0; i < bpGraph.getIntermediates().length; i++) {
    const n = bpGraph.getIntermediates()[i];

    nodes.push({
      id: `${n.id}`,
      data: {
        attributes: n.attributes,
        telemetryType: pipelineType,
      },
      position: grid.getPosition(n.id),
      type: n.type,
    });
  }

  // Lay out destinations
  if (addDestinationCard) {
    nodes.push({
      id: "add-destination",
      data: {
        buttonText: "Destination",
        handlePosition: Position.Left,
        handleType: "target",
        isButton: false,
        onClick: onAddDestination,
        attributes: {},
        telemetryType: pipelineType,
      },
      position: grid.getPosition("add-destination", "destinationNode"),
      type: "uiControlNode",
    });
    nodes.push({
      id: "add-destination-proc",
      position: grid.getPosition("add-destination-proc", "dummyProcessorNode"),
      type: "dummyProcessorNode",
      data: {
        attributes: {},
        telemetryType: pipelineType,
      },
    });
    edge = {
      id: "add-destination-proc_add-destination",
      source: "add-destination-proc",
      target: "add-destination",
      markerEnd: {
        type: MarkerType.ArrowClosed,
      },
      data: {
        connectedNodesAndEdges: [],
        attributes: {
          [AttributeName.ActiveTypeFlags]: PipelineTypeFlags.ALL,
          [AttributeName.SupportedTypeFlags]: PipelineTypeFlags.ALL,
        },
        telemetryType: pipelineType,
      },
      type: "configurationEdge",
    };
    edges.push(edge);
    // connect dummy processor to all the source processors
    for (let i = 0; i < bpGraph.getIntermediates().length; i++) {
      const n = bpGraph.getIntermediates()[i];
      if (isSourceID(n.id)) {
        edge = {
          id: `${n.id}_add-destination-proc`,
          source: `${n.id}`,
          target: "add-destination-proc",
          markerEnd: {
            type: MarkerType.ArrowClosed,
          },
          data: {
            connectedNodesAndEdges: [],
            attributes: {
              [AttributeName.ActiveTypeFlags]: PipelineTypeFlags.ALL,
              [AttributeName.SupportedTypeFlags]: PipelineTypeFlags.ALL,
            },
            telemetryType: pipelineType,
          },
          type: "configurationEdge",
        };
        edges.push(edge);
      }
    }
  } else {
    for (let i = 0; i < bpGraph.getDestinations().length; i++) {
      const n = bpGraph.getDestinations()[i];
      nodes.push({
        id: n.id,
        data: {
          attributes: n.attributes,
          telemetryType: pipelineType,
        },
        position: grid.getPosition(n.id),
        targetPosition: Position.Left,
        type: n.type,
      });
    }
  }

  // find max pipeline position
  let max = 0;

  // This seems like it should be Object.keys(offsets), but alas...
  for (const key in offsets) {
    if (offsets[key] > max) {
      max = offsets[key];
    }
  }

  // Add the add source and add destination buttons
  if (max < 3) {
    max = 3;
  }

  // Add an Add Source button if we aren't using the Add Source Card and
  // the graph is not read only.
  if (!addSourceCard && !readOnly) {
    nodes.push({
      id: "add-source",
      data: {
        buttonText: "Source",
        handlePosition: Position.Right,
        handleType: "source",
        isButton: true,
        onClick: onAddSource,
        attributes: {
          sourceIndex: 0,
          resourceId: "add-source",
          [AttributeName.ActiveTypeFlags]: PipelineTypeFlags.ALL,
          [AttributeName.SupportedTypeFlags]: PipelineTypeFlags.ALL,
        },
        telemetryType: pipelineType,
      },
      position: grid.getPosition("add-source", "uiControlNode"),
      type: "uiControlNode",
    });
  }

  // Add an Add Destination button if we aren't using the Add Destination Card and
  // the graph is not read only.
  if (!addDestinationCard && !readOnly) {
    nodes.push({
      id: "add-destination",
      data: {
        buttonText: "Destination",
        handlePosition: Position.Left,
        handleType: "target",
        isButton: true,
        onClick: onAddDestination,
        attributes: {},
        telemetryType: pipelineType,
      },
      position: grid.getPosition("add-destination", "uiControlNode"),
      type: "uiControlNode",
    });
  }
  if (addDestinationCard && addSourceCard) {
    edge = {
      id: "add-source-proc_add-destination-proc",
      source: "add-source-proc",
      target: "add-destination-proc",
      markerEnd: {
        type: MarkerType.ArrowClosed,
      },
      data: {
        connectedNodesAndEdges: [],
        attributes: {
          [AttributeName.ActiveTypeFlags]: PipelineTypeFlags.ALL,
          [AttributeName.SupportedTypeFlags]: PipelineTypeFlags.ALL,
        },
        telemetryType: pipelineType,
      },
      type: "configurationEdge",
    };
    edges.push(edge);
  }

  for (const e of graph.edges || []) {
    // skip edges that are not of the telemetry type
    const edgePipelineType = e.attributes?.["pipelineType"];
    if (edgePipelineType != null && edgePipelineType !== pipelineType) {
      continue;
    }
    const edge: Edge<EdgeData> & { key: string } = {
      key: e.id,
      id: e.id,
      source: e.source,
      sourceHandle: e.sourceHandle ?? null,
      target: e.target,
      markerEnd: {
        type: MarkerType.ArrowClosed,
      },
      data: {
        attributes: e.attributes ?? {},
        connectedNodesAndEdges: [e.id],
        telemetryType: pipelineType,
      },
      type: "configurationEdge",
      zIndex: 1,
    };

    // Set the edge's ActiveTypeFlag attribute from the intersection of the source
    // and target node ActiveTypeFlag attribute

    // Find the source and target nodes
    if (graph.attributes?.["type"] !== "routes") {
      const sourceNode = nodes.find((n) => n.id === e.source);
      const targetNode = nodes.find((n) => n.id === e.target);

      if (sourceNode && targetNode) {
        // Get the ActiveTypeFlag attribute from the source and target nodes
        const sourceNodeActiveTypeFlag =
          sourceNode.data.attributes?.[AttributeName.ActiveTypeFlags];
        const targetNodeActiveTypeFlag =
          targetNode.data.attributes?.[AttributeName.ActiveTypeFlags];

        // If the source and target nodes have ActiveTypeFlag attributes
        if (sourceNodeActiveTypeFlag && targetNodeActiveTypeFlag) {
          // Set the ActiveTypeFlag attribute as the intersection of the two nodes
          edge.data!.attributes = {
            ...(targetNode.data.attributes ?? {}),
            [AttributeName.ActiveTypeFlags]:
              sourceNodeActiveTypeFlag & targetNodeActiveTypeFlag,
          } as any;
        }
      }
    }

    if (isNodeDisabled(pipelineType || "", edge.data!.attributes)) {
      edge.zIndex = 0;
    }
    edges.push(edge);
  }

  return { nodes, edges };
}
