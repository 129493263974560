import { ButtonBase, Fade } from "@mui/material";
import { EdgeLabelRenderer } from "reactflow";
import colors from "../../../styles/colors";
import { ComponentType } from "../../../utils/classes/component-type";
import { size as nodeSize } from "../../ConfigurationFlowV2/layout-grid";
import { useBPGraph } from "../BPGraphProvider";
import { useRouting } from "./RoutingContext";
import styles from "./routing-node-wrapper.module.scss";

interface RoutingNodeWrapperProps {
  componentType: ComponentType;
  componentPath: string;
  nodeType: string;
  nodeID: string;
  xPos: number;
  yPos: number;
}

export const RoutingNodeWrapper: React.FC<RoutingNodeWrapperProps> = ({
  children,
  componentType,
  componentPath,
  nodeType,
  nodeID,
  xPos,
  yPos,
}) => {
  const { hoveredNode, onMouseEnterNode, onMouseExitNode } = useBPGraph();

  const labelX = xPos + nodeSize(nodeType).width + 8;
  const labelY = yPos + nodeSize(nodeType).height / 2 - 12; // 12 is half the height of the button

  const {
    canConnect,
    hasAvailableConnections,
    onConnect,
    onRouteButtonClick,
    isConnecting,
    readOnly,
  } = useRouting();

  const connectable = componentPath && canConnect(componentPath);

  const classNames = [styles.container];
  if (connectable) {
    classNames.push(styles.highlighted);
  }
  function handleConnectClick() {
    if (!connectable) {
      return;
    }
    onConnect(componentPath!);
  }

  const isRoutable =
    (componentType === "sources" || componentType === "processors") &&
    hasAvailableConnections(componentPath);

  const hovered = nodeID === hoveredNode;

  return (
    <div onClick={handleConnectClick} className={styles.container}>
      <div className={connectable ? styles.highlighted : undefined}>
        {children}
      </div>

      {isRoutable && (
        <EdgeLabelRenderer>
          <Fade in={hovered && !readOnly && !isConnecting}>
            <div
              className={"nopan"}
              style={{
                transform: `translate(${labelX}px,${labelY}px)`,
                pointerEvents: hovered ? "all" : "none",
                position: "absolute",
                backgroundColor: colors.white,
                borderRadius: "50%",
                zIndex: 1,
                opacity: hovered ? 1 : 0,
              }}
            >
              <ButtonBase
                classes={{ root: styles.btn }}
                onClick={(_e) =>
                  onRouteButtonClick(componentType, componentPath)
                }
                onMouseEnter={() => onMouseEnterNode(nodeID)}
                onMouseLeave={onMouseExitNode}
                color="primary"
              >
                +
              </ButtonBase>
            </div>
          </Fade>
        </EdgeLabelRenderer>
      )}
    </div>
  );
};
