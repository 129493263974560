import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  styled,
} from "@mui/material";
import { capitalize } from "lodash";
import { useState } from "react";
import { ReactFlowProvider } from "reactflow";
import { PipelineType } from "../../graphql/generated";
import { Page } from "../../utils/graph/utils";
import { ConfigurationFlowV2 } from "../ConfigurationFlowV2";
import { ChevronDown } from "../Icons";
import { ProcessorDialogContextProvider } from "../ResourceDialog/ProcessorDialogContext";
import { BPGraphProvider } from "./BPGraphProvider";
import { GraphMetricsProvider } from "./GraphMetricsProvider";
import { useV2PipelineGraph } from "./PipelineGraphV2Context";
import { RoutingContextProvider } from "./RoutingContext/RoutingContext";
import styles from "./pipeline-graph-v2.module.scss";

interface PipelineGraphAccordionProps {
  period: string;
  loading: boolean;
  telemetryType: string; // logs, metrics, or traces
  processorPreviewEnabled: boolean;
  expanded: boolean;
  onChange: () => void;
}

const StyledAccordion = styled(Accordion)({
  "&:before": { display: "none" },
  border: "none",
});

const bassSummary = `${styles.accordionSummary}`;
const expandedSummary = `${styles.accordionSummary} ${styles.expanded}`;

export const PipelineGraphAccordion: React.FC<PipelineGraphAccordionProps> = ({
  period,
  loading,
  telemetryType,
  processorPreviewEnabled,
  ...accordionProps
}) => {
  const [summaryClass, setSummaryClass] = useState<string>(
    accordionProps.expanded ? expandedSummary : bassSummary,
  );

  const { readOnlyGraph, configuration, refetchConfiguration } =
    useV2PipelineGraph();

  return (
    <BPGraphProvider pipelineType={telemetryType as PipelineType}>
      <GraphMetricsProvider telemetryType={telemetryType}>
        <ReactFlowProvider>
          <RoutingContextProvider
            readOnly={!!readOnlyGraph}
            configuration={configuration}
            telemetryType={telemetryType}
          >
            <ProcessorDialogContextProvider
              configuration={configuration}
              refetchConfiguration={refetchConfiguration}
              readOnly={!!readOnlyGraph}
              pipelineType={telemetryType as PipelineType}
              withProcessorPreview={processorPreviewEnabled}
            >
              <StyledAccordion {...accordionProps}>
                <AccordionSummary
                  expandIcon={<ChevronDown />}
                  className={summaryClass}
                  onTransitionEnd={() => {
                    accordionProps.expanded
                      ? setSummaryClass(expandedSummary)
                      : setSummaryClass(bassSummary);
                  }}
                >
                  <Typography variant="h6">
                    {capitalize(telemetryType)}
                  </Typography>
                </AccordionSummary>

                <AccordionDetails classes={{ root: styles.card }}>
                  <ConfigurationFlowV2
                    period={period}
                    selectedTelemetry={telemetryType}
                    page={Page.Configuration}
                    loading={loading}
                  />
                </AccordionDetails>
              </StyledAccordion>
            </ProcessorDialogContextProvider>
          </RoutingContextProvider>
        </ReactFlowProvider>
      </GraphMetricsProvider>
    </BPGraphProvider>
  );
};
